import React from 'react';

import Layout from 'src/components/layout/layout';
import LeagueLevelConfigOverview from 'src/components/leagues/level-config/league-level-config-overview';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const LevelConfigPage = () => {
  usePrivateRoute();
  return (
    <Layout>
      <SEO title="League Level Configuration" />
      <LeagueLevelConfigOverview />
    </Layout>
  );
};

export default LevelConfigPage;
