import graphql from 'graphql-tag';

export const GET_LEAGUES = graphql`
  query GetLeagues($pagination: comp_PaginationInput!, $filter: comp_LeaguesFilter) {
    leagues(pagination: $pagination, filter: $filter) {
      totalItems
      items {
        id
        externalId
        primaryAdministrator {
          displayName
        }
        name
        logoUrl
        status
        startDate
        endDate
        level {
          id
          levelGroup {
            id
            name
          }
          name
          type
        }
      }
    }
  }
`;
export const GET_LEAGUES_COORDINATORS = graphql`
  query GetLeaguesCoordinators($filter: FilterResourceInput, $pageArgs: PaginationArgs) {
    resourcesByEntityId(filter: $filter, pageArgs: $pageArgs) {
      total
      results {
        entityType
        entityId
        userId
        person {
          standardGivenName
          standardFamilyName
          email
          extensions {
            name
            value
          }
        }
        roles {
          state
          role
        }
        organisation {
          organisationName
          ownerId
          parentOrganisation {
            organisationName
          }
        }
      }
    }
  }
`;

const COMPETITION_LEVEL_FRAGMENT = graphql`
  fragment CompetitionLevelFields on comp_CompetitionLevel {
    id
    name
    levelGroup {
      id
      name
    }
    orgLevelConfig(organisationId: $orgId) {
      organisationId
      levelId
      competitionConfig {
        description
        requireArea
        isEnabled
      }
      competitionConfigProperties {
        key
        label
        value
        controlType
        valueOptions {
          key
          label
        }
        inherited
        inheritedFrom {
          id
          name
        }
        lockedByOrg
        lockedByAncestor
      }
    }
  }
`;

export const GET_LEAGUE_LEVELS = graphql`
  query LeagueLevels($orgId: ID!) {
    competitionLevels {
      ...CompetitionLevelFields
    }
  }
  ${COMPETITION_LEVEL_FRAGMENT}
`;

export const GET_LEAGUE_LEVEL = graphql`
  query LeagueLevel($levelId: ID!, $orgId: ID!) {
    competitionLevel(id: $levelId) {
      ...CompetitionLevelFields
    }
  }
  ${COMPETITION_LEVEL_FRAGMENT}
`;

export const MUTATE_LEAGUE_LEVEL_CONFIG = graphql`
  mutation UpdateLeagueLevelConfig($orgId: ID!, $levelId: ID!, $config: [comp_CompetitionConfigPropertyInput!]!) {
    updateOrgCompetitionConfig(organisationId: $orgId, levelId: $levelId, config: $config) {
      organisationId
      levelId
      competitionConfig {
        description
        requireArea
        isEnabled
      }
      competitionConfigProperties {
        key
        label
        value
        controlType
        valueOptions {
          key
          label
        }
        inherited
        inheritedFrom {
          id
          name
        }
        lockedByOrg
        lockedByAncestor
      }
    }
  }
`;

export const GET_LEAGUE_LEVEL_GROUPS = graphql`
  query LeagueLevelGroups {
    competitionLevelGroups {
      id
      name
    }
  }
`;
