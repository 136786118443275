import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ArrayParam,
  DateParam,
  DelimitedNumericArrayParam,
  NumberParam,
  QueryParamConfig,
  QueryParamConfigMap,
  StringParam,
  useQueryParams,
} from 'use-query-params';

export interface UrlParams<FilterType> {
  filterKey: keyof FilterType;
  paramName?: string;
  toUrlParam?: (value: any) => any;
  toValue?: (urlParam: any) => any;
  paramType?: QueryParamConfig<any, any>;
}

export interface useFiltersParams<FilterType> {
  initialFilter: FilterType;
  onChange?: (filters: FilterType) => void;
  urlParams?: UrlParams<FilterType>[];
}

export const PARAM_TYPES = {
  DATE: DateParam,
  NUMBER: NumberParam,
  STRING: StringParam,
  ARRAY: ArrayParam,
  NUMERIC_ARRAY: DelimitedNumericArrayParam,
} as const;

export default function useFilters<T>({ initialFilter, onChange, urlParams }: useFiltersParams<T>) {
  type PartialFilter = { [P in keyof T]?: T[P] };
  const [filters, setFilters] = useState<T>(initialFilter);

  useEffect(() => {
    setFilters(initialFilter);
  }, [initialFilter]);

  const filterKeys = useMemo(() => {
    const keys: QueryParamConfigMap = {};
    urlParams?.forEach((p) => (keys[p.paramName ?? (p.filterKey as string)] = p.paramType ?? StringParam));
    return keys;
  }, [urlParams]);

  const [query, setQuery] = useQueryParams(filterKeys);

  // Takes a partial filter and updates the complete filter state, calls onChange and sets url params
  const updateFiltersAndAlert = useCallback(
    (filter: PartialFilter) => {
      const updated = { ...filters, ...filter };
      setFilters(updated);
      onChange?.(updated);

      if (urlParams) {
        const params = {};
        urlParams.forEach((p) => {
          const filterValue = updated[p.filterKey];
          const paramName = p.paramName ?? (p.filterKey as string);
          if (filterValue === undefined || filterValue === initialFilter[p.filterKey]) {
            params[paramName] = undefined;
          } else {
            params[paramName] = p.toUrlParam ? p.toUrlParam(filterValue) : filterValue;
          }
        });
        setQuery(params);
      }
    },
    [onChange, filters, setFilters],
  );

  // On page load/urlParam prop change, set filters to the values from the url params
  useEffect(() => {
    if (urlParams) {
      const urlFilters: PartialFilter = {};
      urlParams.forEach((p) => {
        const urlString = query[p.paramName ?? (p.filterKey as string)];
        if (urlString) {
          const urlValue = p.toValue ? p.toValue(urlString) : urlString;
          if (urlValue) {
            urlFilters[p.filterKey] = urlValue;
          }
        }
      });
      updateFiltersAndAlert(urlFilters);
    }
  }, [urlParams]);

  return [filters, updateFiltersAndAlert] as [typeof filters, typeof updateFiltersAndAlert];
}
