import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import { GET_LEAGUE_LEVEL_GROUPS, GET_LEAGUE_LEVELS } from 'src/components/leagues/leagues-queries';
import * as styles from 'src/components/level-config-overview/level-config-overview.module.less';
import StatusLabel from 'src/components/status-label/status-label';
import { LeagueLevelGroups, LeagueLevelGroups_competitionLevelGroups } from 'src/graphql-types/LeagueLevelGroups';
import { LeagueLevels, LeagueLevels_competitionLevels, LeagueLevelsVariables } from 'src/graphql-types/LeagueLevels';
import useFilters, { UrlParams } from 'src/hooks/use-filters';

import {
  AdminTable,
  DataCols,
  Dropdown,
  Filter,
  FilterSet,
  PageHeader,
  Panel,
} from '@clubspark-react/clubspark-react-tools';

interface LevelConfigFilter {
  levelGroupId: string;
}

const LeagueLevelConfigOverview: React.FC = () => {
  const { t } = useTranslation();
  const orgId = useOrgId();

  const {
    data: levelData,
    loading: levelLoading,
    error: levelError,
  } = useQuery<LeagueLevels, LeagueLevelsVariables>(GET_LEAGUE_LEVELS, {
    client: meshGatewayClient,
    variables: { orgId },
  });

  const {
    data: levelGroupsData,
    loading: levelGroupsLoading,
    error: levelGroupsError,
  } = useQuery<LeagueLevelGroups>(GET_LEAGUE_LEVEL_GROUPS, {
    client: meshGatewayClient,
  });

  const loading = levelLoading || levelGroupsLoading;
  const error = levelError || levelGroupsError;

  const columns = useMemo<DataCols<LeagueLevels_competitionLevels>>(() => {
    return [
      {
        key: 'name',
        title: t('level'),
        getValue: (l) => l.name,
      },
      {
        key: 'isEnabled',
        title: ' ',
        getValue: (l) => {
          const isEnabled = l.orgLevelConfig?.competitionConfig?.isEnabled;
          return (
            <div className={styles.enableLabel}>
              <StatusLabel variety={isEnabled ? 'success' : 'neutral'}>
                {t(isEnabled ? 'enabled' : 'disabled')}
              </StatusLabel>
            </div>
          );
        },
      },
    ];
  }, [t]);

  const toValue = useCallback((f: string) => f.toLowerCase(), []);
  const toUrlParam = useCallback((f: string) => f.toLowerCase(), []);
  const urlParams = useMemo<UrlParams<LevelConfigFilter>[]>(
    () => [{ filterKey: 'levelGroupId', toUrlParam, toValue }],
    [toUrlParam, toValue],
  );
  const initialFilter = useMemo(
    () => ({ levelGroupId: levelGroupsData?.competitionLevelGroups[0].id }) as LevelConfigFilter,
    [levelGroupsData],
  );
  const filterArgs = useMemo(() => ({ urlParams, initialFilter }), [initialFilter, urlParams]);
  const [{ levelGroupId }, updateFilters] = useFilters(filterArgs);

  const sortedData = useMemo(() => {
    if (!levelData?.competitionLevels || !levelGroupId) return [];

    return levelData?.competitionLevels?.filter(({ levelGroup }) => levelGroup.id === levelGroupId);
  }, [levelData, levelGroupId]);

  const getLevelGroupDropdownLabel = (levelGroup: LeagueLevelGroups_competitionLevelGroups) => {
    return {
      label: t(levelGroup.name),
      value: levelGroup.id,
    };
  };

  return (
    <>
      <div>
        <PageHeader title={t('level configurations')} />
      </div>
      <Panel>
        <FilterSet>
          <Filter label={t('circuit')}>
            <Dropdown
              selected={levelGroupId}
              options={levelGroupsData?.competitionLevelGroups.map(getLevelGroupDropdownLabel) ?? []}
              onSelect={({ value }) => updateFilters({ levelGroupId: value })}
            />
          </Filter>
        </FilterSet>
        <AdminTable
          data={sortedData}
          columns={columns}
          loading={loading}
          error={error?.message}
          emptyStateMessage={t('no level configurations found')}
          onRowClick={(r) => navigate(`/leagues/level-config/${r.id}`)}
        />
      </Panel>
    </>
  );
};

export default LeagueLevelConfigOverview;
