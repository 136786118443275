import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';

import * as styles from './status-label.module.less';

export type LabelVariety = 'success' | 'error' | 'warning' | 'neutral' | 'neutral2' | 'highlight' | 'primary';

interface Props extends SpacingProps {
  corners?: 'box' | 'rounded';
  variety?: LabelVariety;
}

const StatusLabel: React.FC<Props> = ({ children, corners = 'box', variety = 'neutral', spacing }) => {
  const spacingClass = useSpacing(spacing);
  return <span className={cx(styles.label, styles[corners], styles[variety], spacingClass)}>{children}</span>;
};

export const withStatusMapping =
  <
    T extends {
      [key: string]: { type: Props['variety']; transKey: string };
    },
  >(
    statusMap: T,
  ) =>
  ({ status, ...props }: Omit<Props, 'variety'> & { status: keyof T }) => {
    const { type, transKey } = statusMap[status] ?? {};
    const { t } = useTranslation();

    return (
      <StatusLabel {...props} variety={type}>
        {t(transKey)}
      </StatusLabel>
    );
  };

export default StatusLabel;
